import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const Customization = () => {
  return (
    <Box sx={{ padding: "2rem", backgroundColor: "#E8E8E8" }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          color: "#FF8C00",
          marginBottom: "1rem",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Customization
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box
            component="img"
            src="Designer.png"
            alt="Customization"
            sx={{
              width: "100%",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="body1"
            sx={{
              color: "#1e2b46",
              marginTop: "0.5rem",
              fontSize: "1.2rem", // Increase font size
              lineHeight: "2.5", // Increase line height
              textAlign: {
                xs: "left",
                md: "justify",
              }, // Optional: justify text alignment for a cleaner look
              whiteSpace: "normal",
              // wordSpacing:"-2px",

              fontWeight: "600",
            }}
          >
            At Dipisha Fashline Enterprises LLP, we understand that
            individuality and personal expression are key to making a statement
            with fashion. That's why we offer extensive customization options
            for both our scarves and jewelry collections. At Dipisha Fashline
            Enterprises LLP, we are committed to delivering exceptional
            customized scarves and jewelry that resonate with our clients'
            individual styles and needs. Our dedication to quality craftsmanship
            and personalized service ensures that each custom piece is a true
            reflection of personal elegance and sophistication.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Customization;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "@mui/material";

const categories = [
  { name: "Scarves", image: "scarv.jpeg" },
  { name: "Shawls", image: "shawl.jpeg" },
  { name: "Bandana", image: "band.jpeg" },
  { name: "Earrings", image: "earing.png" },
  { name: "Bangles", image: "bangle.jpeg" },
  { name: "Necklaces", image: "neck.jpeg" },
  { name: "Bags", image: "bag.jpeg" },
];

function CategoryCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="slider-container"
      style={{
        width: "100%",
        padding: "0 15px",
        backgroundColor: "#edd8d8",
        height: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#333333",
          textAlign: "center",
        
        }}
      >
        Our Products
      </Typography>
      <Slider {...settings} style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        {categories.map((category, index) => (
          <div key={index} style={{ padding: "0 5px" }}>
            <div
              style={{
                marginTop: "2rem",
                textAlign: "center",
                backgroundColor: "#edd8d8",
                borderRadius: "8px",
                padding: "10px",
                height: "55vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={category.image}
                alt={category.name}
                style={{
                  width: "90%",
                  height: "80%",
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {category.name}
              </Typography>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CategoryCarousel;
